<template>
  <div  v-if="$route.params.programID === 'all'" class="about w-11/12 lg:w-7/12 mx-auto">
  <div class="description w-4/5 lg:w-2/3 py-4" id="programs">
    <p class="text-left text-2xl lg:text-4xl font-semibold">
      Мы предлагаем более 50 программ обучения:
    </p>
  </div>
  <div class="grid grid-cols-1 lg:grid-cols-3 gap-3">
    <div v-for="program,i in getPrograms" :key="i">
      <button type="button"
              @click="routeProgram(i)"
              class="w-full h-full inline-block px-6 font-bold text-lg py-2.5 bg-blue-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out">
        {{ program.name }}
      </button>

    </div>
  </div>
  </div>
  <div v-else class="w-11/12 lg:w-3/4 mx-auto pt-6" id="program">
    <div class="flex flex-col lg:flex-row justify-start gap-5">
      <button class="lg:ml-4 rounded-lg w-1/2 lg:w-1/12 bg-blue-500 text-white font-bold p-2 px-4" @click="back()">
        Назад
      </button>
      <h1 class="text-3xl lg:text-4xl uppercase font-bold text-left">{{ getPrograms[getCurrentProgram].name }}</h1>
    </div>
    <div class="description w-5/6 my-8 ml-4 gap-5">
    </div>

    <div class="w-full relative flex flex-row justify-start items-center">
      <h1 class="inline ml-4 text-2xl lg:text-4xl uppercase font-bold text-left">
        Программы обучения по направлению "{{ getPrograms[getCurrentProgram].name }}"
      </h1>
    </div>
    <div :class="gridClass">
      <div v-for="direction,i in getPrograms[getCurrentProgram].directions" :key="i"
           class="relative flex flex-col justify-start bg-gradient-to-r from-rose-100 to-teal-100">
        <!-- card -->
        <div class="mx-auto flex w-72 lg:w-80 flex-col justify-center bg-white rounded-2xl shadow-xl shadow-slate-300/60">
          <!-- img -->
          <img class="aspect-video h-72 rounded-t-2xl object-cover object-center"
               :src="'/images/education/programs/' + direction.img"/>
          <!-- text information -->
          <div class="p-4 relative min-h-44 flex flex-col justify-between">
            <div class="inline-block text-md lg:text-lg lg:text-center text-left text-slate-600 pb-2">{{ direction.name }}</div>

            <div class="flex flex-col justify-center items-center">
              <div>

                <div class="inline-block mr-2 mb-2 text-blue-500 text-md lg:text-lg font-bold bg-white rounded-lg p-2">
                  {{ direction.time }}
                </div>
                <a :href="'tel:' + getPhone">
                  <div class="inline-block mr-2 mb-2 text-white text-md lg:text-lg font-bold bg-blue-500 rounded-lg p-2 lg:p-3 px-4 lg:px-6">
                    {{ priceObfuscate(direction.price) }}
                  </div>
                </a>
                <a :href="'tel:' + getPhone">
                  <div class="inline-block mr-2 mb-2 text-white text-md lg:text-lg font-bold bg-green-500 rounded-lg p-2 lg:p-3 px-4 lg:px-6">
                    <font-awesome-icon icon="phone" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Program",
  data() {
    return {
      description: ''
    }
  },
  methods: {
    back() {
      this.$router.push({name: 'Program', params: {programID: 'all'}})
      //
      // setTimeout(function () {
      //   document.getElementById('home').scrollIntoView({
      //     behavior: 'smooth',
      //     block: 'start'
      //   });
      // }, 11)
    },
    getDescription() {
      console.log(this.getPrograms[this.$route.params.programID]);
      let d = this.getPrograms[this.$route.params.programID].description;
      let splitted = d.split(/\r?\n/);
      var result = '';
      splitted.forEach(e =>
          document.querySelector('.description').insertAdjacentHTML('afterbegin', '<p class="text-left text-xl mb-3">' + e + '</p>')
      )
      return result;
    },
    priceObfuscate(price) {
      return !isNaN(price) ? price + ' Р' : '';
    },
    routeProgram(i) {
      this.$store.commit('currentProgram', i)
      this.$router.push({name: 'Program', params: {programID: i}})
      setTimeout(function () {
        document.getElementById('program').scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }, 10)
    },
  },
  computed: {
    getPhone() {
      console.log(this.$store.state.phone);
      return this.$store.state.phone
    },
    getPrograms() {
      return this.$store.state.programs
    },
    getCurrentProgram() {
      return this.$store.state.currentProgram
    },
    gridClass() {
      return {
        'cards grid gap-5 m-5 mt-10 mx-auto': true,
        'grid-cols-1 lg:grid-cols-3': this.getPrograms[this.$route.params.programID].directions.length > 1,
        'grid-cols-1': this.getPrograms[this.$route.params.programID].directions.length === 1
      }
    }
  },

  updated: function (){
    if (this.$route.params.programID != 'all') this.getDescription();
    if(this.$route.params.programID == 'all') document.getElementById('programs').scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
    else document.getElementById('program').scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  },
  mounted: function () {
    this.$store.commit('currentProgram', this.$route.params.programID)



  }
}
</script>

<style scoped>

</style>