<template>

  <div class="about w-11/12 lg:w-7/12 mx-auto pt-6 relative text-left" id="about">
    <h1 class="text-3xl lg:text-4xl uppercase font-bold text-left">О НАС</h1>
    <div class="description w-4/5 lg:w-2/3 my-4 ml-4 gap-3">
      <p class="text-left text-xl lg:text-2xl mb-3">
        Наша компания ООО «Образование» работает на рынке с 2016 года. Среди наших клиентов – государственные учреждения и коммерческие компании, малый и средний бизнес, крупные предприятия различных секторов экономики, образовательные учреждения, юридические и физические лица.
        </p>
      <p class="text-left text-xl lg:text-2xl mb-3">
      Мы работаем на всей территории Российской Федерации. Компания имеет все необходимые лицензии, аттестаты и сертификаты соответствия.
      </p>
      <p class="text-left text-xl lg:text-2xl mb-3">
        Наша задача - профессиональное выполнение своей работы. Наша компания – сплоченная команда профессионалов, имеющая репутацию надежного партнера.
      </p>

      <router-link class="" to="/program/all"> <div class="inline-block mr-2 mb-2 text-white text-lg font-bold bg-blue-500 rounded-lg p-3 px-6">
        Посмотреть направления
      </div>
      </router-link>
      <div class="absolute hidden lg:block h-64 w-64 top-24 right-20"
           style="background-image:url('/images/production/edu.gif'); opacity:0.9; background-size: cover;"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "About",
  mounted() {
    document.getElementById('about').scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }
}

</script>